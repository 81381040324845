<template>
  <svg
    v-if="computedProps.elementTag === 'svg'"
    :key="computedProps.value"
    :jsbarcode-value="computedProps.value"
    :jsbarcode-format="computedProps.format"
    :jsbarcode-width="computedProps.width"
    :jsbarcode-height="computedProps.height"
    :jsbarcode-textmargin="computedProps.textMargin"
    :jsbarcode-fontoptions="computedProps.fontOptions"
    :jsbarcode-font="computedProps.font"
    :jsbarcode-linecolor="computedProps.lineColor"
    :jsbarcode-background="computedProps.background"
    :jsbarcode-margin="computedProps.margin"
    :jsbarcode-displayvalue="computedProps.displayValue"
    :jsbarcode-fontsize="computedProps.fontSize"
    :jsbarcode-textalign="computedProps.textAlign"
    :jsbarcode-textposition="computedProps.textPosition"
    :jsbarcode-flat="computedProps.flat"
    class="barcode"
    :style="` display: ${computedProps.value ? undefined : 'none'} `"
  />
</template>

<script lang="ts" setup>
import JsBarcode from 'jsbarcode'

const props = withDefaults(
  defineProps<{
    value: string
    format?: string
    width?: number
    height?: number
    displayValue?: boolean
    text?: string | null
    fontOptions?: string | null
    font?: string | null
    textAlign?: string | null
    textPosition?: string | null
    textMargin?: number | null
    fontSize?: number
    background?: string | null
    lineColor?: string | null
    margin?: number | null
    marginTop?: number | null
    marginBottom?: number | null
    marginLeft?: number | null
    marginRight?: number | null
    flat?: boolean
    ean128?: boolean
    elementTag?: string
  }>(),
  {
    format: 'CODE128',
    width: 2,
    height: 100,
    elementTag: 'svg',
    displayValue: true,
    text: null,
    fontOptions: null,
    font: 'monospace',
    textAlign: 'center',
    textPosition: 'bottom',
    textMargin: 2,
    fontSize: 20,
    background: null,
    lineColor: '#000000',
    margin: 10,
    marginTop: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    flat: false,
    ean128: false
  }
)

const computedProps = computed(() => {
  const {
    value,
    format,
    width,
    height,
    elementTag,
    displayValue,
    text,
    fontOptions,
    font,
    textAlign,
    textPosition,
    textMargin,
    fontSize,
    background,
    lineColor,
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    flat,
    ean128
  } = props

  return {
    value,
    format,
    width,
    height,
    elementTag,
    displayValue,
    text,
    fontOptions,
    font,
    textAlign,
    textPosition,
    textMargin,
    fontSize,
    background,
    lineColor,
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    flat,
    ean128
  }
})

// State

// Mounted
onMounted(() => {
  JsBarcode('.barcode').init()
})

watch(computedProps, () => JsBarcode('.barcode').init(), {
  immediate: true,
  deep: true
})
</script>
